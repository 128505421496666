import {
  init,
  BrowserTracing,
  vueRouterInstrumentation,
  replayIntegration
} from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const router = useRouter()
  const config = useRuntimeConfig()

  init({
    app: [vueApp],
    dsn: config.public.sentryDsn,
    integrations: [
      new BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: vueRouterInstrumentation(router)
      }),
      replayIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,

    // Set tracesSampleRate to 0.1 to capture 10%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    replaysSessionSampleRate: 0.1,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
  });
});