import { default as agent_45callbackH0BMCWWbd5Meta } from "/src/pages/agent-callback.vue?macro=true";
import { default as indexuPA8f60s2NMeta } from "/src/pages/agent/my-refers/[refer_id]/index.vue?macro=true";
import { default as contractjE5PQDiRspMeta } from "/src/pages/agent/my-refers/[refer_id]/management/contract.vue?macro=true";
import { default as indexBLpyXDQkfXMeta } from "/src/pages/agent/my-refers/[refer_id]/management/index.vue?macro=true";
import { default as indexAn8XhyReS3Meta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/cancel-contract/index.vue?macro=true";
import { default as index57btcsfRVJMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/index.vue?macro=true";
import { default as editby6q8HPbTHMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/make-appointment/edit.vue?macro=true";
import { default as indexQhB8Fp5AiDMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/make-appointment/index.vue?macro=true";
import { default as indexiAdIAUAZfFMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/sale-contract/index.vue?macro=true";
import { default as edit_45appointment1Cv8s2mLZKMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/upload-sale-contract/edit-appointment.vue?macro=true";
import { default as indexEf4p4wfu0IMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/upload-sale-contract/index.vue?macro=true";
import { default as indexon0P6TAvHvMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/index.vue?macro=true";
import { default as historyeqcn0MJIx4Meta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/interest/history.vue?macro=true";
import { default as reportBFvgNPujcTMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/interest/report.vue?macro=true";
import { default as historyYcEBpiv3OeMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/monthly/history.vue?macro=true";
import { default as report5KxmkWuYXQMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/monthly/report.vue?macro=true";
import { default as historyosc0dHUG4HMeta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/visit/history.vue?macro=true";
import { default as report7fj8o9hqN5Meta } from "/src/pages/agent/my-refers/[refer_id]/management/sale-report/visit/report.vue?macro=true";
import { default as bank_45accountLSHBLuS50vMeta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/bank-account.vue?macro=true";
import { default as edit_45updaterBVZJmDxq9Meta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/edit-update.vue?macro=true";
import { default as edit_45uploadJOXye8r6yUMeta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/edit-upload.vue?macro=true";
import { default as indexgbsE5iJP4vMeta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/index.vue?macro=true";
import { default as updatedTcZfPYZJxMeta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/update.vue?macro=true";
import { default as uploadMIxPWJA4x7Meta } from "/src/pages/agent/my-refers/[refer_id]/management/transference/upload.vue?macro=true";
import { default as indexn2RRZIPeFNMeta } from "/src/pages/agent/my-refers/[refer_id]/management/update-marketing/index.vue?macro=true";
import { default as marketing_45offline_45dailyYin2qjzrqVMeta } from "/src/pages/agent/my-refers/[refer_id]/management/update-marketing/marketing-offline-daily.vue?macro=true";
import { default as marketing_45online_45dailyDkGGYnRYvFMeta } from "/src/pages/agent/my-refers/[refer_id]/management/update-marketing/marketing-online-daily.vue?macro=true";
import { default as upload_45contractQA3vQ0NvG7Meta } from "/src/pages/agent/my-refers/[refer_id]/management/upload-contract.vue?macro=true";
import { default as reviewNxim7eLsSbMeta } from "/src/pages/agent/my-refers/[refer_id]/review.vue?macro=true";
import { default as index5vvhpv6nBeMeta } from "/src/pages/agent/my-refers/index.vue?macro=true";
import { default as _91refer_id_93mGqwDUCX8gMeta } from "/src/pages/agent/refers/[refer_id].vue?macro=true";
import { default as indexw0CxtWz1ysMeta } from "/src/pages/agent/refers/index.vue?macro=true";
import { default as login1P9zcCj80aMeta } from "/src/pages/auth/login.vue?macro=true";
import { default as facebook_45callbackBxKp4YENCwMeta } from "/src/pages/facebook-callback.vue?macro=true";
import { default as forget_45passwordBfLbP1ZtpnMeta } from "/src/pages/forget-password.vue?macro=true";
import { default as google_45callbackqmRJpe7kX4Meta } from "/src/pages/google-callback.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as line_45callbackjmGBxkmWa3Meta } from "/src/pages/line-callback.vue?macro=true";
import { default as nayoo_45callbackBarTtAO0sJMeta } from "/src/pages/nayoo-callback.vue?macro=true";
import { default as property_45owner_45callbackNtUdGyP4c6Meta } from "/src/pages/property-owner-callback.vue?macro=true";
import { default as indexdXNy5pQeyPMeta } from "/src/pages/property-owner/index.vue?macro=true";
import { default as _91ordinal_93OFxfVfVJKFMeta } from "/src/pages/property-owner/refer/[refer_id]/report-marketing/[ordinal].vue?macro=true";
import { default as indextUuWsVYEMTMeta } from "/src/pages/property-owner/refer/[refer_id]/report-marketing/index.vue?macro=true";
import { default as review8QV2OTtlylMeta } from "/src/pages/property-owner/refer/[refer_id]/review.vue?macro=true";
import { default as indexnieLKhH7RlMeta } from "/src/pages/property-owner/refer/[refer_id]/sale-agreement/index.vue?macro=true";
import { default as indexHu2jJusoXZMeta } from "/src/pages/property-owner/refer/[refer_id]/sale-report/interest/index.vue?macro=true";
import { default as index8VmlvcVIO5Meta } from "/src/pages/property-owner/refer/[refer_id]/sale-report/monthly/index.vue?macro=true";
import { default as indexAPfRTBAHcwMeta } from "/src/pages/property-owner/refer/[refer_id]/sale-report/visit/index.vue?macro=true";
import { default as show_45confirmSL7CIUOrG4Meta } from "/src/pages/property-owner/refer/[refer_id]/show-confirm.vue?macro=true";
import { default as show_45have_45agentxKFGLKGmzRMeta } from "/src/pages/property-owner/refer/[refer_id]/show-have-agent.vue?macro=true";
import { default as indexP8YRMZtvnrMeta } from "/src/pages/property-owner/refer/[refer_id]/transference/index.vue?macro=true";
import { default as registerl6FWyGNZLVMeta } from "/src/pages/property-owner/register.vue?macro=true";
export default [
  {
    name: agent_45callbackH0BMCWWbd5Meta?.name ?? "agent-callback",
    path: agent_45callbackH0BMCWWbd5Meta?.path ?? "/agent-callback",
    meta: agent_45callbackH0BMCWWbd5Meta || {},
    alias: agent_45callbackH0BMCWWbd5Meta?.alias || [],
    redirect: agent_45callbackH0BMCWWbd5Meta?.redirect || undefined,
    component: () => import("/src/pages/agent-callback.vue").then(m => m.default || m)
  },
  {
    name: indexuPA8f60s2NMeta?.name ?? "agent-my-refers-refer_id",
    path: indexuPA8f60s2NMeta?.path ?? "/agent/my-refers/:refer_id()",
    meta: indexuPA8f60s2NMeta || {},
    alias: indexuPA8f60s2NMeta?.alias || [],
    redirect: indexuPA8f60s2NMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: contractjE5PQDiRspMeta?.name ?? "agent-my-refers-refer_id-management-contract",
    path: contractjE5PQDiRspMeta?.path ?? "/agent/my-refers/:refer_id()/management/contract",
    meta: contractjE5PQDiRspMeta || {},
    alias: contractjE5PQDiRspMeta?.alias || [],
    redirect: contractjE5PQDiRspMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/contract.vue").then(m => m.default || m)
  },
  {
    name: indexBLpyXDQkfXMeta?.name ?? "agent-my-refers-refer_id-management",
    path: indexBLpyXDQkfXMeta?.path ?? "/agent/my-refers/:refer_id()/management",
    meta: indexBLpyXDQkfXMeta || {},
    alias: indexBLpyXDQkfXMeta?.alias || [],
    redirect: indexBLpyXDQkfXMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexAn8XhyReS3Meta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-cancel-contract",
    path: indexAn8XhyReS3Meta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/cancel-contract",
    meta: indexAn8XhyReS3Meta || {},
    alias: indexAn8XhyReS3Meta?.alias || [],
    redirect: indexAn8XhyReS3Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/cancel-contract/index.vue").then(m => m.default || m)
  },
  {
    name: index57btcsfRVJMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement",
    path: index57btcsfRVJMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement",
    meta: index57btcsfRVJMeta || {},
    alias: index57btcsfRVJMeta?.alias || [],
    redirect: index57btcsfRVJMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: editby6q8HPbTHMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-make-appointment-edit",
    path: editby6q8HPbTHMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/make-appointment/edit",
    meta: editby6q8HPbTHMeta || {},
    alias: editby6q8HPbTHMeta?.alias || [],
    redirect: editby6q8HPbTHMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/make-appointment/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQhB8Fp5AiDMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-make-appointment",
    path: indexQhB8Fp5AiDMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/make-appointment",
    meta: indexQhB8Fp5AiDMeta || {},
    alias: indexQhB8Fp5AiDMeta?.alias || [],
    redirect: indexQhB8Fp5AiDMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/make-appointment/index.vue").then(m => m.default || m)
  },
  {
    name: indexiAdIAUAZfFMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-sale-contract",
    path: indexiAdIAUAZfFMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/sale-contract",
    meta: indexiAdIAUAZfFMeta || {},
    alias: indexiAdIAUAZfFMeta?.alias || [],
    redirect: indexiAdIAUAZfFMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/sale-contract/index.vue").then(m => m.default || m)
  },
  {
    name: edit_45appointment1Cv8s2mLZKMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-upload-sale-contract-edit-appointment",
    path: edit_45appointment1Cv8s2mLZKMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/upload-sale-contract/edit-appointment",
    meta: edit_45appointment1Cv8s2mLZKMeta || {},
    alias: edit_45appointment1Cv8s2mLZKMeta?.alias || [],
    redirect: edit_45appointment1Cv8s2mLZKMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/upload-sale-contract/edit-appointment.vue").then(m => m.default || m)
  },
  {
    name: indexEf4p4wfu0IMeta?.name ?? "agent-my-refers-refer_id-management-sale-agreement-upload-sale-contract",
    path: indexEf4p4wfu0IMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-agreement/upload-sale-contract",
    meta: indexEf4p4wfu0IMeta || {},
    alias: indexEf4p4wfu0IMeta?.alias || [],
    redirect: indexEf4p4wfu0IMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-agreement/upload-sale-contract/index.vue").then(m => m.default || m)
  },
  {
    name: indexon0P6TAvHvMeta?.name ?? "agent-my-refers-refer_id-management-sale-report",
    path: indexon0P6TAvHvMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report",
    meta: indexon0P6TAvHvMeta || {},
    alias: indexon0P6TAvHvMeta?.alias || [],
    redirect: indexon0P6TAvHvMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/index.vue").then(m => m.default || m)
  },
  {
    name: historyeqcn0MJIx4Meta?.name ?? "agent-my-refers-refer_id-management-sale-report-interest-history",
    path: historyeqcn0MJIx4Meta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/interest/history",
    meta: historyeqcn0MJIx4Meta || {},
    alias: historyeqcn0MJIx4Meta?.alias || [],
    redirect: historyeqcn0MJIx4Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/interest/history.vue").then(m => m.default || m)
  },
  {
    name: reportBFvgNPujcTMeta?.name ?? "agent-my-refers-refer_id-management-sale-report-interest-report",
    path: reportBFvgNPujcTMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/interest/report",
    meta: reportBFvgNPujcTMeta || {},
    alias: reportBFvgNPujcTMeta?.alias || [],
    redirect: reportBFvgNPujcTMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/interest/report.vue").then(m => m.default || m)
  },
  {
    name: historyYcEBpiv3OeMeta?.name ?? "agent-my-refers-refer_id-management-sale-report-monthly-history",
    path: historyYcEBpiv3OeMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/monthly/history",
    meta: historyYcEBpiv3OeMeta || {},
    alias: historyYcEBpiv3OeMeta?.alias || [],
    redirect: historyYcEBpiv3OeMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/monthly/history.vue").then(m => m.default || m)
  },
  {
    name: report5KxmkWuYXQMeta?.name ?? "agent-my-refers-refer_id-management-sale-report-monthly-report",
    path: report5KxmkWuYXQMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/monthly/report",
    meta: report5KxmkWuYXQMeta || {},
    alias: report5KxmkWuYXQMeta?.alias || [],
    redirect: report5KxmkWuYXQMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/monthly/report.vue").then(m => m.default || m)
  },
  {
    name: historyosc0dHUG4HMeta?.name ?? "agent-my-refers-refer_id-management-sale-report-visit-history",
    path: historyosc0dHUG4HMeta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/visit/history",
    meta: historyosc0dHUG4HMeta || {},
    alias: historyosc0dHUG4HMeta?.alias || [],
    redirect: historyosc0dHUG4HMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/visit/history.vue").then(m => m.default || m)
  },
  {
    name: report7fj8o9hqN5Meta?.name ?? "agent-my-refers-refer_id-management-sale-report-visit-report",
    path: report7fj8o9hqN5Meta?.path ?? "/agent/my-refers/:refer_id()/management/sale-report/visit/report",
    meta: report7fj8o9hqN5Meta || {},
    alias: report7fj8o9hqN5Meta?.alias || [],
    redirect: report7fj8o9hqN5Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/sale-report/visit/report.vue").then(m => m.default || m)
  },
  {
    name: bank_45accountLSHBLuS50vMeta?.name ?? "agent-my-refers-refer_id-management-transference-bank-account",
    path: bank_45accountLSHBLuS50vMeta?.path ?? "/agent/my-refers/:refer_id()/management/transference/bank-account",
    meta: bank_45accountLSHBLuS50vMeta || {},
    alias: bank_45accountLSHBLuS50vMeta?.alias || [],
    redirect: bank_45accountLSHBLuS50vMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/bank-account.vue").then(m => m.default || m)
  },
  {
    name: edit_45updaterBVZJmDxq9Meta?.name ?? "agent-my-refers-refer_id-management-transference-edit-update",
    path: edit_45updaterBVZJmDxq9Meta?.path ?? "/agent/my-refers/:refer_id()/management/transference/edit-update",
    meta: edit_45updaterBVZJmDxq9Meta || {},
    alias: edit_45updaterBVZJmDxq9Meta?.alias || [],
    redirect: edit_45updaterBVZJmDxq9Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/edit-update.vue").then(m => m.default || m)
  },
  {
    name: edit_45uploadJOXye8r6yUMeta?.name ?? "agent-my-refers-refer_id-management-transference-edit-upload",
    path: edit_45uploadJOXye8r6yUMeta?.path ?? "/agent/my-refers/:refer_id()/management/transference/edit-upload",
    meta: edit_45uploadJOXye8r6yUMeta || {},
    alias: edit_45uploadJOXye8r6yUMeta?.alias || [],
    redirect: edit_45uploadJOXye8r6yUMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/edit-upload.vue").then(m => m.default || m)
  },
  {
    name: indexgbsE5iJP4vMeta?.name ?? "agent-my-refers-refer_id-management-transference",
    path: indexgbsE5iJP4vMeta?.path ?? "/agent/my-refers/:refer_id()/management/transference",
    meta: indexgbsE5iJP4vMeta || {},
    alias: indexgbsE5iJP4vMeta?.alias || [],
    redirect: indexgbsE5iJP4vMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/index.vue").then(m => m.default || m)
  },
  {
    name: updatedTcZfPYZJxMeta?.name ?? "agent-my-refers-refer_id-management-transference-update",
    path: updatedTcZfPYZJxMeta?.path ?? "/agent/my-refers/:refer_id()/management/transference/update",
    meta: updatedTcZfPYZJxMeta || {},
    alias: updatedTcZfPYZJxMeta?.alias || [],
    redirect: updatedTcZfPYZJxMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/update.vue").then(m => m.default || m)
  },
  {
    name: uploadMIxPWJA4x7Meta?.name ?? "agent-my-refers-refer_id-management-transference-upload",
    path: uploadMIxPWJA4x7Meta?.path ?? "/agent/my-refers/:refer_id()/management/transference/upload",
    meta: uploadMIxPWJA4x7Meta || {},
    alias: uploadMIxPWJA4x7Meta?.alias || [],
    redirect: uploadMIxPWJA4x7Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/transference/upload.vue").then(m => m.default || m)
  },
  {
    name: indexn2RRZIPeFNMeta?.name ?? "agent-my-refers-refer_id-management-update-marketing",
    path: indexn2RRZIPeFNMeta?.path ?? "/agent/my-refers/:refer_id()/management/update-marketing",
    meta: indexn2RRZIPeFNMeta || {},
    alias: indexn2RRZIPeFNMeta?.alias || [],
    redirect: indexn2RRZIPeFNMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/update-marketing/index.vue").then(m => m.default || m)
  },
  {
    name: marketing_45offline_45dailyYin2qjzrqVMeta?.name ?? "agent-my-refers-refer_id-management-update-marketing-marketing-offline-daily",
    path: marketing_45offline_45dailyYin2qjzrqVMeta?.path ?? "/agent/my-refers/:refer_id()/management/update-marketing/marketing-offline-daily",
    meta: marketing_45offline_45dailyYin2qjzrqVMeta || {},
    alias: marketing_45offline_45dailyYin2qjzrqVMeta?.alias || [],
    redirect: marketing_45offline_45dailyYin2qjzrqVMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/update-marketing/marketing-offline-daily.vue").then(m => m.default || m)
  },
  {
    name: marketing_45online_45dailyDkGGYnRYvFMeta?.name ?? "agent-my-refers-refer_id-management-update-marketing-marketing-online-daily",
    path: marketing_45online_45dailyDkGGYnRYvFMeta?.path ?? "/agent/my-refers/:refer_id()/management/update-marketing/marketing-online-daily",
    meta: marketing_45online_45dailyDkGGYnRYvFMeta || {},
    alias: marketing_45online_45dailyDkGGYnRYvFMeta?.alias || [],
    redirect: marketing_45online_45dailyDkGGYnRYvFMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/update-marketing/marketing-online-daily.vue").then(m => m.default || m)
  },
  {
    name: upload_45contractQA3vQ0NvG7Meta?.name ?? "agent-my-refers-refer_id-management-upload-contract",
    path: upload_45contractQA3vQ0NvG7Meta?.path ?? "/agent/my-refers/:refer_id()/management/upload-contract",
    meta: upload_45contractQA3vQ0NvG7Meta || {},
    alias: upload_45contractQA3vQ0NvG7Meta?.alias || [],
    redirect: upload_45contractQA3vQ0NvG7Meta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/management/upload-contract.vue").then(m => m.default || m)
  },
  {
    name: reviewNxim7eLsSbMeta?.name ?? "agent-my-refers-refer_id-review",
    path: reviewNxim7eLsSbMeta?.path ?? "/agent/my-refers/:refer_id()/review",
    meta: reviewNxim7eLsSbMeta || {},
    alias: reviewNxim7eLsSbMeta?.alias || [],
    redirect: reviewNxim7eLsSbMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/[refer_id]/review.vue").then(m => m.default || m)
  },
  {
    name: index5vvhpv6nBeMeta?.name ?? "agent-my-refers",
    path: index5vvhpv6nBeMeta?.path ?? "/agent/my-refers",
    meta: index5vvhpv6nBeMeta || {},
    alias: index5vvhpv6nBeMeta?.alias || [],
    redirect: index5vvhpv6nBeMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/my-refers/index.vue").then(m => m.default || m)
  },
  {
    name: _91refer_id_93mGqwDUCX8gMeta?.name ?? "agent-refers-refer_id",
    path: _91refer_id_93mGqwDUCX8gMeta?.path ?? "/agent/refers/:refer_id()",
    meta: _91refer_id_93mGqwDUCX8gMeta || {},
    alias: _91refer_id_93mGqwDUCX8gMeta?.alias || [],
    redirect: _91refer_id_93mGqwDUCX8gMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/refers/[refer_id].vue").then(m => m.default || m)
  },
  {
    name: indexw0CxtWz1ysMeta?.name ?? "agent-refers",
    path: indexw0CxtWz1ysMeta?.path ?? "/agent/refers",
    meta: indexw0CxtWz1ysMeta || {},
    alias: indexw0CxtWz1ysMeta?.alias || [],
    redirect: indexw0CxtWz1ysMeta?.redirect || undefined,
    component: () => import("/src/pages/agent/refers/index.vue").then(m => m.default || m)
  },
  {
    name: login1P9zcCj80aMeta?.name ?? "auth-login",
    path: login1P9zcCj80aMeta?.path ?? "/auth/login",
    meta: login1P9zcCj80aMeta || {},
    alias: login1P9zcCj80aMeta?.alias || [],
    redirect: login1P9zcCj80aMeta?.redirect || undefined,
    component: () => import("/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: facebook_45callbackBxKp4YENCwMeta?.name ?? "facebook-callback",
    path: facebook_45callbackBxKp4YENCwMeta?.path ?? "/facebook-callback",
    meta: facebook_45callbackBxKp4YENCwMeta || {},
    alias: facebook_45callbackBxKp4YENCwMeta?.alias || [],
    redirect: facebook_45callbackBxKp4YENCwMeta?.redirect || undefined,
    component: () => import("/src/pages/facebook-callback.vue").then(m => m.default || m)
  },
  {
    name: forget_45passwordBfLbP1ZtpnMeta?.name ?? "forget-password",
    path: forget_45passwordBfLbP1ZtpnMeta?.path ?? "/forget-password",
    meta: forget_45passwordBfLbP1ZtpnMeta || {},
    alias: forget_45passwordBfLbP1ZtpnMeta?.alias || [],
    redirect: forget_45passwordBfLbP1ZtpnMeta?.redirect || undefined,
    component: () => import("/src/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: google_45callbackqmRJpe7kX4Meta?.name ?? "google-callback",
    path: google_45callbackqmRJpe7kX4Meta?.path ?? "/google-callback",
    meta: google_45callbackqmRJpe7kX4Meta || {},
    alias: google_45callbackqmRJpe7kX4Meta?.alias || [],
    redirect: google_45callbackqmRJpe7kX4Meta?.redirect || undefined,
    component: () => import("/src/pages/google-callback.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: line_45callbackjmGBxkmWa3Meta?.name ?? "line-callback",
    path: line_45callbackjmGBxkmWa3Meta?.path ?? "/line-callback",
    meta: line_45callbackjmGBxkmWa3Meta || {},
    alias: line_45callbackjmGBxkmWa3Meta?.alias || [],
    redirect: line_45callbackjmGBxkmWa3Meta?.redirect || undefined,
    component: () => import("/src/pages/line-callback.vue").then(m => m.default || m)
  },
  {
    name: nayoo_45callbackBarTtAO0sJMeta?.name ?? "nayoo-callback",
    path: nayoo_45callbackBarTtAO0sJMeta?.path ?? "/nayoo-callback",
    meta: nayoo_45callbackBarTtAO0sJMeta || {},
    alias: nayoo_45callbackBarTtAO0sJMeta?.alias || [],
    redirect: nayoo_45callbackBarTtAO0sJMeta?.redirect || undefined,
    component: () => import("/src/pages/nayoo-callback.vue").then(m => m.default || m)
  },
  {
    name: property_45owner_45callbackNtUdGyP4c6Meta?.name ?? "property-owner-callback",
    path: property_45owner_45callbackNtUdGyP4c6Meta?.path ?? "/property-owner-callback",
    meta: property_45owner_45callbackNtUdGyP4c6Meta || {},
    alias: property_45owner_45callbackNtUdGyP4c6Meta?.alias || [],
    redirect: property_45owner_45callbackNtUdGyP4c6Meta?.redirect || undefined,
    component: () => import("/src/pages/property-owner-callback.vue").then(m => m.default || m)
  },
  {
    name: indexdXNy5pQeyPMeta?.name ?? "property-owner",
    path: indexdXNy5pQeyPMeta?.path ?? "/property-owner",
    meta: indexdXNy5pQeyPMeta || {},
    alias: indexdXNy5pQeyPMeta?.alias || [],
    redirect: indexdXNy5pQeyPMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/index.vue").then(m => m.default || m)
  },
  {
    name: _91ordinal_93OFxfVfVJKFMeta?.name ?? "property-owner-refer-refer_id-report-marketing-ordinal",
    path: _91ordinal_93OFxfVfVJKFMeta?.path ?? "/property-owner/refer/:refer_id()/report-marketing/:ordinal()",
    meta: _91ordinal_93OFxfVfVJKFMeta || {},
    alias: _91ordinal_93OFxfVfVJKFMeta?.alias || [],
    redirect: _91ordinal_93OFxfVfVJKFMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/report-marketing/[ordinal].vue").then(m => m.default || m)
  },
  {
    name: indextUuWsVYEMTMeta?.name ?? "property-owner-refer-refer_id-report-marketing",
    path: indextUuWsVYEMTMeta?.path ?? "/property-owner/refer/:refer_id()/report-marketing",
    meta: indextUuWsVYEMTMeta || {},
    alias: indextUuWsVYEMTMeta?.alias || [],
    redirect: indextUuWsVYEMTMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/report-marketing/index.vue").then(m => m.default || m)
  },
  {
    name: review8QV2OTtlylMeta?.name ?? "property-owner-refer-refer_id-review",
    path: review8QV2OTtlylMeta?.path ?? "/property-owner/refer/:refer_id()/review",
    meta: review8QV2OTtlylMeta || {},
    alias: review8QV2OTtlylMeta?.alias || [],
    redirect: review8QV2OTtlylMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/review.vue").then(m => m.default || m)
  },
  {
    name: indexnieLKhH7RlMeta?.name ?? "property-owner-refer-refer_id-sale-agreement",
    path: indexnieLKhH7RlMeta?.path ?? "/property-owner/refer/:refer_id()/sale-agreement",
    meta: indexnieLKhH7RlMeta || {},
    alias: indexnieLKhH7RlMeta?.alias || [],
    redirect: indexnieLKhH7RlMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/sale-agreement/index.vue").then(m => m.default || m)
  },
  {
    name: indexHu2jJusoXZMeta?.name ?? "property-owner-refer-refer_id-sale-report-interest",
    path: indexHu2jJusoXZMeta?.path ?? "/property-owner/refer/:refer_id()/sale-report/interest",
    meta: indexHu2jJusoXZMeta || {},
    alias: indexHu2jJusoXZMeta?.alias || [],
    redirect: indexHu2jJusoXZMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/sale-report/interest/index.vue").then(m => m.default || m)
  },
  {
    name: index8VmlvcVIO5Meta?.name ?? "property-owner-refer-refer_id-sale-report-monthly",
    path: index8VmlvcVIO5Meta?.path ?? "/property-owner/refer/:refer_id()/sale-report/monthly",
    meta: index8VmlvcVIO5Meta || {},
    alias: index8VmlvcVIO5Meta?.alias || [],
    redirect: index8VmlvcVIO5Meta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/sale-report/monthly/index.vue").then(m => m.default || m)
  },
  {
    name: indexAPfRTBAHcwMeta?.name ?? "property-owner-refer-refer_id-sale-report-visit",
    path: indexAPfRTBAHcwMeta?.path ?? "/property-owner/refer/:refer_id()/sale-report/visit",
    meta: indexAPfRTBAHcwMeta || {},
    alias: indexAPfRTBAHcwMeta?.alias || [],
    redirect: indexAPfRTBAHcwMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/sale-report/visit/index.vue").then(m => m.default || m)
  },
  {
    name: show_45confirmSL7CIUOrG4Meta?.name ?? "property-owner-refer-refer_id-show-confirm",
    path: show_45confirmSL7CIUOrG4Meta?.path ?? "/property-owner/refer/:refer_id()/show-confirm",
    meta: show_45confirmSL7CIUOrG4Meta || {},
    alias: show_45confirmSL7CIUOrG4Meta?.alias || [],
    redirect: show_45confirmSL7CIUOrG4Meta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/show-confirm.vue").then(m => m.default || m)
  },
  {
    name: show_45have_45agentxKFGLKGmzRMeta?.name ?? "property-owner-refer-refer_id-show-have-agent",
    path: show_45have_45agentxKFGLKGmzRMeta?.path ?? "/property-owner/refer/:refer_id()/show-have-agent",
    meta: show_45have_45agentxKFGLKGmzRMeta || {},
    alias: show_45have_45agentxKFGLKGmzRMeta?.alias || [],
    redirect: show_45have_45agentxKFGLKGmzRMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/show-have-agent.vue").then(m => m.default || m)
  },
  {
    name: indexP8YRMZtvnrMeta?.name ?? "property-owner-refer-refer_id-transference",
    path: indexP8YRMZtvnrMeta?.path ?? "/property-owner/refer/:refer_id()/transference",
    meta: indexP8YRMZtvnrMeta || {},
    alias: indexP8YRMZtvnrMeta?.alias || [],
    redirect: indexP8YRMZtvnrMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/refer/[refer_id]/transference/index.vue").then(m => m.default || m)
  },
  {
    name: registerl6FWyGNZLVMeta?.name ?? "property-owner-register",
    path: registerl6FWyGNZLVMeta?.path ?? "/property-owner/register",
    meta: registerl6FWyGNZLVMeta || {},
    alias: registerl6FWyGNZLVMeta?.alias || [],
    redirect: registerl6FWyGNZLVMeta?.redirect || undefined,
    component: () => import("/src/pages/property-owner/register.vue").then(m => m.default || m)
  }
]