<script lang="ts" setup>
useHead({
  title: 'Oops!',
})

defineProps({
  error: Object,
});
</script>

<template>
  <div>
    <v-container class="flex flex-col gap-6 justify-center items-center mt-6">
      <SvgoHaveComfirm class="!w-[253px] !h-[210px]" filled/>
      <div class="flex flex-col gap-1">
        <LazyErrorBadRequest v-if="error?.statusCode === 400" />
        <LazyErrorUnauthorized v-else-if="error?.statusCode === 401" />
        <LazyErrorForbidden v-else-if="error?.statusCode === 403"/>
        <LazyErrorNotFound v-else-if="error?.statusCode === 404" />
        <LazyErrorServerError v-else-if="error?.statusCode === 500"/>
        <LazyErrorBadGateway v-else-if="error?.statusCode === 502"/>
      </div>
    </v-container>
    <slot />
  </div>
</template>

<style scoped></style>
